import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CurrentLangSwitched, I18nStateSelectors } from '@app/store';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hae-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatToolbarModule, MatMenuModule, TranslateModule, AsyncPipe],
})
export class HeaderComponent {
  public currentStateLanguage$ = this.store.select(
    I18nStateSelectors.currentLanguage,
  );

  public supportedLanguages$ = this.store.select(
    I18nStateSelectors.supportedLanguages,
  );

  public showLanguagesSwitcher$ = this.supportedLanguages$.pipe(
    map((locales) => locales.length > 1),
  );

  constructor(private store: Store) {}

  public switchCurrentLang(selectedLang: string): void {
    this.store.dispatch(new CurrentLangSwitched(selectedLang));
  }
}
