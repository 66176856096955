import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthStateSelectors } from '@app/store';
import { SanitizePipe } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'hae-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, SanitizePipe, TranslateModule, AsyncPipe],
})
export class FooterComponent {
  isUserLogged$ = this.store.select(AuthStateSelectors.user);

  constructor(private store: Store) {}
}
