<mat-toolbar class="header">
  @if (showLanguagesSwitcher$ | async) {
    <span>
      <div>
        @if (currentStateLanguage$ | async; as currentStateLanguage) {
          <p
            [matMenuTriggerFor]="menu"
            >
            {{ currentStateLanguage | translate }}
          </p>
        }
      </div>
      <mat-menu #menu="matMenu" xPosition="before">
        @for (locale of supportedLanguages$ | async; track locale) {
          <button
            (click)="switchCurrentLang(locale)"
            mat-menu-item
            >
            {{ locale | translate }}
          </button>
        }
      </mat-menu>
    </span>
  }
</mat-toolbar>
